import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import circlechart from '../../Assests/icons/Circle Chart.svg';
import devMode from '../../Assests/icons/Developer Mode.svg';
import circle from '../../Assests/icons/Ellipse 6.svg';
import shop from '../../Assests/icons/Online Shopping.svg';
import chart from '../../Assests/icons/Pix.svg';
import vector from '../../Assests/icons/Vector.svg';

const data = [
    {
        id: 1,
        img: chart,
        title: 'Logo Design',
        desc: 'A unique logo speaks volumes about your brand. Let us design one that captures your essence and leaves a lasting impression.',
        active: true
    },
    {
        id: 2,
        img: vector,
        title: 'App development',
        desc: 'After digesting your idea/project, our professional mobile app designers will build out a beautiful wireframe & Clickable Prototype to bring your project to life with color and brand',
    },
    {
        id: 3,
        img: devMode,
        title: 'Webapp development',
        desc: 'Enhance your website effortlessly with our intuitive web development tools for',
    },
    {
        id: 4,
        img: circlechart,
        title: 'Social media marketing',
        desc: 'Enhance your website effortlessly with our intuitive web development tools for',
    },
    {
        id: 5,
        img: shop,
        title: 'E-commerce',
        desc: 'Empower your business with our strategic solutions designed for sustainable',
    }
];

const SalesDetail = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const containerRef = useRef(null);
    const itemsRefs = useRef([]);

    useEffect(() => {
        const container = containerRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = itemsRefs.current.indexOf(entry.target);
                        setActiveIndex(index);
                    }
                });
            },
            { threshold: 0.5 } // Adjust this value if needed
        );

        itemsRefs.current.forEach((ref) => {
            observer.observe(ref);
        });

        return () => {
            observer.disconnect();
        };
    }, []);


    return (
        <div className="sales py-50" id="services" >
            <div className="py-50">
                <section className="sec9 py-50">
                    <motion.div className="tabs">
                        <div className="sec9-left">
                            <div className='headText'>
                                <p className="text ">Do You Want To</p>
                                <p className="boostText">Boost Your</p>
                                <p className="salesText">Sales? <br />
                                </p>
                            </div>
                            <div className='subText'>
                                <div className='imageDiv'>
                                    <img src={circle} alt="eclpse" loading='lazy' />
                                </div>
                                <div className='textDiv'>
                                    <p >Join the ranks of our successful clients in 2023. With over 1100 websites developed. We've propelled businesses to millions in revenue. Let's fuel your success story together.</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div className="tabs" ref={containerRef}>
                        <AnimatePresence>
                            <section>
                                {data?.map((elem, index) => (
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.5 }}
                                        className={`sec9-right mb-60 ${index === activeIndex ? 'active' : ''}`}
                                        key={elem.id}
                                        ref={(el) => {
                                            itemsRefs.current[index] = el;
                                        }}
                                    >
                                        <div className='number_wrapper'>
                                            <div className='number_count'>
                                                0{elem.id}
                                            </div>
                                            <div className='borderImage'>
                                                <img src={elem.img} alt='dasd' loading='lazy' />
                                            </div>
                                        </div>
                                        <div className='card-title'>
                                            <p className='title'>{elem.title}</p>
                                            <p className='descp'>{elem.desc}</p>
                                        </div>
                                    </motion.div>
                                ))}
                            </section>
                        </AnimatePresence>
                    </motion.div>
                </section>
            </div>
        </div>
    );
};

export default SalesDetail;
