import mainLogo from "../../Assests/images/logo-cyphion.png";

import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import hambugerIcon from "../../Assests/icons/hamburger-s.svg";

const Header = ({ setActiveName }) => {
  const [open, setOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeClass, setActiveClass] = useState('')
  const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false)
  const dropdownRef = useRef(null);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const closeDropdown = () => {
    setOpen(false);
  };
  const scrollToDiv = (divId) => {
    setActiveClass(divId)
    closeDropdown()
    var element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };



  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".navMenu li")
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (showNavbar) {
      // Disable body scrolling when the box is open
      document.body.style.overflow = 'hidden';
      setIsBodyOverflowHidden(true);
    } else {
      // Enable body scrolling when the box is closed
      document.body.style.overflow = 'auto';
      setIsBodyOverflowHidden(false);
    }

    return () => {
      // Cleanup: enable body scrolling on component unmount
      document.body.style.overflow = 'auto';
    };
  }, [showNavbar]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div

      className={`header d-flex align-items-center flex-row justify-content-evenly py-16 px-10 f-family-poppin ${isSticky ? "sticky" : ""
        }`}
    >
      <div className="mr-5 head-logo" onClick={() => { scrollToDiv("hero-section"); setShowNavbar(false); setActiveName("") }}>
        <Link to="/"> <img src={mainLogo} alt="Cyphion Logo" className="cursor-pointer head-logo-img" /></Link>

      </div>
      <div className={`${showNavbar ? "mobileMenu" : "navMenu"}  transtionAnima `} ref={dropdownRef}>
        <div >
          <ul>
            {/* {showNavbar && ( */}
            <li>
              <div
                onClick={handleShowNavbar}
                className={`text-right fs-24 cursor-pointer pr-24 mr-16 showX`}
              >
                <RxCross2 size={24} />
              </div>
            </li>
            {/* )} */}
            <li onClick={() => { scrollToDiv("hero-section"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'hero-section' ? "active" : ''}`}>
              {" "}
              <Link to="/">Home</Link>
            </li>
            {/* this service includes dropdown in multiple category  */}


            <li onClick={() => { scrollToDiv("portfolio"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'portfolio' ? "active" : ''}`}>
              {" "}
              <Link to="/#portfolio">Portfolio</Link>
            </li>
            <li>
              <Link to={`/#services`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("services"); setShowNavbar(false); setActiveName("Web Development"); }}>
                Services
              </Link>
            </li>
            <li onClick={() => { scrollToDiv("ourProcess"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'ourProcess' ? "active" : ''}`}>
              {" "}
              <Link to="/#ourProcess">Our process</Link>
            </li>
            {/* <li onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'price' ? "active" : ''}`}>
              <Link to="/#price">
                Price
              </Link>
            </li> */}
            <li onClick={() => { scrollToDiv("contact"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'contact' ? "active" : ''}`}>
              <Link to="/#contact">
                Contact Us
              </Link>
            </li>
            {/* <li onClick={() => { scrollToDiv("faqs"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'faqs' ? "active" : ''}`}>
              <Link to="/faq">FAQ</Link>{" "}
            </li> */}
          </ul>
        </div>
        {/* <div className={`${showNavbar ? "btnContact" : "ml-16 hide-btn"}  transtion-05`}>
          <a href="tel:(307) 417-0652" className="primary-button d-flex align-items-center flex-row justify-content-around">
            <img src={phoneIcon} alt="phoneIcon" className="phoneIco" loading='lazy' />
            (307) 417-0652
          </a>
        </div> */}
      </div>

      <div onClick={handleShowNavbar} className="mobile-menu">
        <img src={hambugerIcon} alt="phoneIcon" className="hambugerIcon" loading='lazy' />
      </div>
    </div>
  );
};

export default Header;
